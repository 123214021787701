import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "My 2018 Review",
  "author": "tzookb",
  "type": "post",
  "date": "2019-01-01T11:11:12.000Z",
  "url": "/2019/01/my-2018-review/",
  "categories": ["News"],
  "tags": ["productivity"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` As me and my wife finished our review of 2018 and see what we have achieved or not. What did we do well or not so well. I decided to share my retrospective for 2018 well first of all for me but for others to get some inspiration. So as I started reading a lot of books, in 2018 I got to read 26 books in total. Amazon really enjoys my new habit 🙂 In 2019 Im planning to double my reading to 52 books. So for my rules of breaking my tasks to doable tasks that will mean one book per week, or even more acurate I`}{`’`}{`ll need to complete 1/7 of a book every day. As I have started using kindle for my book reading I`}{`’`}{`ll need to see my completion percentage to bump every day in 14%. There are the books I have read:`}<br parentName="p"></br>{`
`}{`(I`}{`’`}{`ll start adding reviews and links to those I have read and will read, some are quite good books! and some`}{`…`}{` are less good) `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`blue ocean strategy `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the ultimate sales letter `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the complete guide to fasting `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`obesity code `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`dotcom secrets `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the irresistible offer `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`money `}{`–`}{` master the game `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`never split the difference `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`it doesn’t have to be crazy at work `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`pre-suasion  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`as a man thinketh `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`miracle morning `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`multipliers `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`extreme ownership `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`awaken the giant within `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`4-hour body `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`tribe of mentors `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`unshakeable `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the richest man in Babylon `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`how to write copy that sales `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`influence `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`this book will teach you how to write better `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`clean architecture  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`loopholes of real estate `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the book on estimating rehab costs `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`How Come That Idiot`}{`’`}{`s Rich and I`}{`’`}{`m Not  With the above I learned a lot of courses for `}{`“`}{`docker`}{`”`}{`, programming, react and more. I moved to a new country and a new home. I got married 🙂 and much more`}{`…`}{` For next year I`}{`’`}{`ll read the book per week and some other learning goals I want to have: `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Improve my english, especially my accent`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`finish docker course and be able to deploy to prod with external volumes to store my databases and file uploads`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`get my aws developer certificate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`continue learning on copywriting and marketing and start blasting with my writing Other Life goals: `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Close up all visa issues we have`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Upgrade my job role to more management and a touch of marketing and product`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`start interacting with groups on Real Estate, Marketing and Copywriting (posting and commenting)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Go to our delayed honeymoon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Have our first kid (or at least start the process) 🙂`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`learn more on investments and invest my lazy money `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`continue with wall climbing`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`take the wife to dance lessons`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      